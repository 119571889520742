<template>
  <article id="wrap">

    <header>
      <img src="@/assets/img/sutekinasekai_maintenance.png" class="w100">
    </header>


    <section class="contents">

      <div class="contentInner">
        <div class="bold ta_c mb_10">ただいまメンテナンス中です。</div>
        <div class="mb_20">ただいま、みなさんがより住みやすくなるようにメンテナンス工事中
          です。工事が終わるまでもう少しだけ待ってね。</div>

        <div class="white_bg_area2">
          <div class="maintenance">メンテナンス時間</div>
          <div class="maintenance txt_14">2020年12月31日 0:00～0:50</div>
        </div><!-- .white_bg_area -->

      </div><!--.contentInner-->

    </section><!--.contents-->

  </article>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Signup',
  components: {
  }
}
</script>
